import { useState } from "react";

import Cookie from "js-cookie";

import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import styled from "styled-components";
import ClassNames from "classnames";
import ConfettiExplosion from "react-confetti-explosion";

import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { StateHandler } from "../../../../model/Utilities/Types";
import { TransactionCp, ChargepointMap } from "../../../../model/Classes/Chargepoint";
import { PricingType, AccessType } from "../../../../model/Classes/Area";

import getCurrencySymbol from "../../../../utils/getCurrencySymbol";

import Toggle from "react-toggle";
import "react-toggle/style.css";

import { MaintenanceNotification } from "../../../GeneralComponents/MaintenanceNotification";
import ChargepointDropdown from "../../../Level1/Chargepoint/ChargepointDropdown";
import { ConnectorIdDropdown } from "../../../Level1/Chargepoint/ConnectorIdDropdown";
import { SpotPriceWidget } from "./SpotPriceWidget";

import logo from "../../../../resources/WatteryLogoGreen.svg";

const enum EnumInvoicingMethod {
  test = 0,
  reporting = 1,
  invoice = 2,
}

export const StartNewTransactionView = ({
  selectedChargepoint,
  setSelectedChargepoint,
  setSelectedConnector,
  publicChargepoints,
  myChargepoints,
  recentChargepoints,
  handleSubmit,
  connectorId,
  mustSetConnectorId,
  hasInterrupts,
  isLoadBalanced,
  starting,
}: {
  selectedChargepoint?: TransactionCp;
  setSelectedChargepoint: Function;
  setSelectedConnector: Function;
  publicChargepoints: ChargepointMap;
  myChargepoints: ChargepointMap;
  recentChargepoints: ChargepointMap;
  handleSubmit: (optimizationParams: { range: number; time: number } | null) => Promise<void>;
  connectorId: number | null;
  setConnectorId: StateHandler<number | null>;
  mustSetConnectorId: boolean;
  hasInterrupts: boolean;
  isLoadBalanced: boolean;
  starting: boolean;
}) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  let optimizationParamsCookie: { range?: number; time?: number };
  try {
    optimizationParamsCookie = JSON.parse(Cookie.get("optimization_parameters") || "{}");
  } catch (e) {
    optimizationParamsCookie = {};
  }

  // Collect price information in array where members are later combined
  // to a single string with a plus sign (+) as separator.
  let chargePrice: Array<string | Number> = [];

  const optimizationRangeOptions = [25, 50, 100, 200, 400];

  const currencySymbol = getCurrencySymbol(selectedChargepoint?.currency);

  const [isOptimized, setIsOptimized] = useState<boolean>(false);
  const [optimizationRange, setOptimizationRange] = useState<number>(
    optimizationParamsCookie?.range || optimizationRangeOptions[1]
  );
  const [optimizationTime, setOptimizationTime] = useState<number>(
    optimizationParamsCookie?.time || (new Date().getHours() + 2) % 24
  ); // Default to hour after next

  const [confettiHasFired, setConfettiHasFired] = useState<boolean>(false);

  if (!!!selectedChargepoint) {
    // Do nothing, just for clarity with this structure
  } else if (selectedChargepoint?.free_charging === 1) {
    chargePrice.push(t("components.startTransaction.view.freeOfCharge"));
  } else if (selectedChargepoint.pricing_type === PricingType.fixed) {
    switch (selectedChargepoint.access_type) {
      case AccessType.free:
        chargePrice.push(Number(0).toFixed(2) + ` ${currencySymbol}`);
        break;
      case AccessType.private:
        chargePrice.push(Number(selectedChargepoint.price).toFixed(2) + ` ${currencySymbol}/kWh`);
        break;
      case AccessType.privateAndPublic:
        const hasPrivateAccess = myChargepoints.has(String(selectedChargepoint.charge_point_id));
        if (hasPrivateAccess) {
          chargePrice.push(Number(selectedChargepoint.price).toFixed(2) + ` ${currencySymbol}/kWh`);
        } else {
          if (Number(selectedChargepoint.public_start_price) > 0) {
            chargePrice.push(Number(selectedChargepoint.public_start_price).toFixed(2) + ` ${currencySymbol}/kWh`);
          }
          chargePrice.push(Number(selectedChargepoint.public_price).toFixed(2) + ` ${currencySymbol}/kWh`);
        }
        break;
      case AccessType.public:
        if (Number(selectedChargepoint.public_start_price) > 0) {
          chargePrice.push(Number(selectedChargepoint.public_start_price).toFixed(2) + ` ${currencySymbol}/kWh`);
        }
        chargePrice.push(Number(selectedChargepoint.public_price).toFixed(2) + ` ${currencySymbol}/kWh`);
        break;
    }
  } else if (selectedChargepoint.pricing_type === PricingType.spot) {
    if (Number(selectedChargepoint.margin) !== 0) {
      chargePrice.push(Number(selectedChargepoint.margin).toFixed(3) + ` ${currencySymbol}/kWh`); // NB! Three decimals!
    }

    const invMethod = selectedChargepoint.invoicing_method;
    if (invMethod === EnumInvoicingMethod.invoice) {
      chargePrice.push(t("components.startTransaction.view.spotPriceMin")); // Minimum 0 €
    } else if (invMethod === EnumInvoicingMethod.test || invMethod === EnumInvoicingMethod.reporting) {
      chargePrice.push(t("components.startTransaction.view.spotPrice"));
    }
  }

  return (
    <Container>
      <Row style={{ justifyContent: "center" }}>
        <Image className="logo" src={logo} alt="Wattery logo" />
        <MaintenanceNotification />
      </Row>

      <Row className="mb-3">
        <Form>
          <Row>
            <Col style={{ textAlign: "left" }}>
              {!!!selectedChargepoint && (
                <DropdownLabel>{t("components.startTransaction.static.header")}</DropdownLabel>
              )}
              <ChargepointDropdown
                selectedChargepoint={selectedChargepoint}
                onSelectChargepoint={setSelectedChargepoint}
                publicChargepoints={publicChargepoints}
                myChargepoints={myChargepoints}
                recentChargepoints={recentChargepoints}
              />
            </Col>
          </Row>

          {!!selectedChargepoint && (
            <>
              {mustSetConnectorId && (
                <Row className="mt-1 mb-1">
                  <ConnectorIdDropdown
                    numberOfConnectors={selectedChargepoint.number_of_connectors}
                    connectorId={connectorId}
                    onSelectConnector={setSelectedConnector}
                  />
                </Row>
              )}
              {selectedChargepoint.address && (
                <Row className="mt-1">
                  <Col style={{ textAlign: "left" }}>
                    <FontAwesomeIcon icon={faGlobe} /> {selectedChargepoint.address}
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <StartTransactionContainer>
                    <div>
                      <div>
                        {selectedChargepoint.ownerName &&
                          t("components.startTransaction.view.owner", {
                            owner: selectedChargepoint.ownerName,
                            interpolation: { escapeValue: false }, // Otherwise escapes slashes etc.
                          })}
                      </div>
                      <div className="price">{chargePrice.join(" + ")}</div>

                      {selectedChargepoint.pricing_type === PricingType.spot &&
                        isLoadBalanced &&
                        (!mustSetConnectorId || connectorId) && (
                          <div className="optimization">
                            {isOptimized && !confettiHasFired && (
                              <ConfettiExplosion onComplete={() => setConfettiHasFired(true)} />
                            )}

                            <div>
                              <label>{t("components.startTransaction.view.optimizePrice")}</label>
                              <Toggle
                                onChange={(e) => {
                                  setIsOptimized(e.target.checked);
                                }}
                              />
                            </div>

                            <div
                              className={ClassNames({
                                optimizationParams: true,
                                shown: isOptimized,
                                animate__animated: isOptimized,
                                animate__fadeIn: isOptimized,
                              })}
                            >
                              {t("components.startTransaction.view.chargeAtLeast")}{" "}
                              <select
                                onChange={(e) => setOptimizationRange(parseInt(e.target.value))}
                                value={optimizationRange}
                              >
                                {optimizationRangeOptions.map((range, i) => (
                                  <option key={i} value={range}>
                                    {range * 0.2} kWh / {range} km
                                  </option>
                                ))}
                              </select>{" "}
                              {t("components.startTransaction.view.rangeBy")}{" "}
                              <select
                                onChange={(e) => setOptimizationTime(parseInt(e.target.value))}
                                value={optimizationTime}
                              >
                                {Array.from({ length: 24 }).map((_, index) => {
                                  let hours = (index + new Date().getHours() + 1) % 24;
                                  return (
                                    <option key={index} value={hours} disabled={!index}>
                                      {hours}:00
                                    </option>
                                  );
                                })}
                              </select>
                              {t("components.startTransaction.view.rangeBySuffix")}
                            </div>
                          </div>
                        )}
                    </div>
                    <div>
                      {hasInterrupts || (mustSetConnectorId && !connectorId) ? (
                        <StyledStartButton className="disabled" disabled={true}>
                          {t("components.startTransaction.buttons.start.text")}
                        </StyledStartButton>
                      ) : starting ? (
                        <StyledStartButton className="starting" disabled={true}>
                          <FontAwesomeIcon icon={faSpinner} spinPulse />
                        </StyledStartButton>
                      ) : (
                        <StyledStartButton
                          onClick={() =>
                            handleSubmit(isOptimized ? { range: optimizationRange, time: optimizationTime } : null)
                          }
                        >
                          {t("components.startTransaction.buttons.start.text")}
                        </StyledStartButton>
                      )}
                    </div>
                  </StartTransactionContainer>
                </Col>
              </Row>

              <Row className="mt-4">
                {selectedChargepoint.pricing_type !== 0 && (
                  <SpotPriceWidget
                    chargepointId={selectedChargepoint.charge_point_id}
                    margin={selectedChargepoint.margin}
                  />
                )}
              </Row>

              <Row className="mt-4">
                <Col>
                  <div className="subtle text-align-center">
                    {selectedChargepoint.invoicing_method === EnumInvoicingMethod.invoice &&
                      selectedChargepoint.pricing_type === PricingType.spot &&
                      t("components.startTransaction.view.spotFooter", {
                        currency: getCurrencySymbol(selectedChargepoint?.currency),
                      })}
                    {t("components.startTransaction.view.footer")}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Row>
    </Container>
  );
};

const DropdownLabel = styled.span`
  display: block;
  text-align: left;
  font-size: 90%;
`;

const StartTransactionContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  & > div {
    margin-top: 2rem;

    div.price {
      font-size: 110%;
      font-weight: bold;
    }

    div.optimization {
      margin-top: 1rem;

      label {
        vertical-align: top;
        margin-right: 0.5rem;
      }

      div.optimizationParams {
        visibility: hidden;
        display: inline-block;
        padding: 2px 6px;
        border-radius: 6px;
        background: #eee;

        &.shown {
          visibility: visible;
        }

        select {
          border: none;
          color: green;
          background: #eee;
          text-align: right;

          option {
            font-size: 90%;
          }
        }
      }
    }
  }
`;

const StyledStartButton = styled(Button)`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 150%;
  line-height: 1.1;
  box-shadow: 0px 0px 6px -2px #777, inset 0px 0px 8px #fff;
  border-width: 0.5px;

  &:active {
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.lightGreen} !important;
    border-color: transparent !important;
    padding: 5px 13px;
  }

  &.disabled {
    background: #888;
    border-color: #888;
    color: #ffffffaa;
  }

  &.starting {
    opacity: 0.8;
    font-size: 300%;
  }
`;
